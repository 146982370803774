<template>
  <div>
    <b-jumbotron fluid overlay>
      <b-img center src="@/assets/logo.png" alt="Park & Main Hartford"></b-img>
    </b-jumbotron>

    <b-jumbotron class="rendering" fluid overlay> </b-jumbotron>

    <b-jumbotron class="highlights" center fluid overlay>
      <h1>
        <span class="red">Residences</span> <span class="white">+ </span>
        <span class="grey">STOREFRONTS</span>
      </h1>
      <b-row>
        <b-col md="4" offset-md="4">
          <p class="pb-2">
            {{ highlightParagraph }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset-md="3" class="pb-2"
          ><b-button size="lg" class="serif p-4" variant="light" @click="showForm = !showForm"
            >Stay in the Loop <font-awesome-icon icon="arrow-right"/></b-button
        ></b-col>
      </b-row>
    </b-jumbotron>
    <b-container fluid v-if="showForm" class="form-bg p-5">
      <b-container>
        <b-row>
          <b-col>
            <h1>Stay in the Loop</h1>
            We'll keep you up to date on what's going on.
            <!-- Begin Mailchimp Signup Form -->
            <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />

            <div id="mc_embed_signup">
              <form
                action="https://parkmainhartford.us7.list-manage.com/subscribe/post?u=c2cb850253b0a5594b570a446&amp;id=f2bc0fd741"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate"
                target="_blank"
                novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                  <div class="mc-field-group">
                    <label for="mce-EMAIL">Email Address <span class="asterisk">*</span> </label>
                    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" />
                  </div>
                  <div class="mc-field-group">
                    <label for="mce-FNAME">First Name <span class="asterisk">*</span> </label>
                    <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" />
                  </div>
                  <div class="mc-field-group">
                    <label for="mce-LNAME">Last Name <span class="asterisk">*</span> </label>
                    <input type="text" value="" name="LNAME" class="required" id="mce-LNAME" />
                  </div>
                  <div class="mc-field-group">
                    <label for="mce-INTEREST">Interested In <span class="asterisk">*</span> </label>
                    <select name="INTEREST" class="required" id="mce-INTEREST">
                      <option value=""></option>
                      <option value="Apartment">Apartment</option>
                      <option value="Retail">Retail</option>
                      <option value="Commercial">Commercial</option>
                    </select>
                  </div>
                  <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_c2cb850253b0a5594b570a446_f2bc0fd741" tabindex="-1" value="" />
                  </div>
                  <div class="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      class="button"
                      onClick="fbq('track', 'Lead');"
                    />
                  </div>
                </div>
              </form>
            </div>

            <!--End mc_embed_signup-->
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid>
      <b-row
        ><b-col class="mb-5 pt-4 pb-4">
          <VueSlickCarousel v-bind="settings">
            <b-img center src="@/assets/gal.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_02.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_03.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_04.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_05.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_06.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_07.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_08.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_09.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_10.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_11.jpg" alt="Park & Main Hartford"></b-img>
            <b-img center src="@/assets/gal_12.jpg" alt="Park & Main Hartford"></b-img>
          </VueSlickCarousel> </b-col
      ></b-row>
    </b-container>
    <b-container fluid class="insta p-5">
      <b-row align-v="center">
        <b-col class="p-3"
          ><a href="https://instagram.com/parkmainhartford"><font-awesome-icon :icon="['fab', 'instagram']"/></a
        ></b-col>
      </b-row>
      <b-row align-v="center" class="p-3">
        <b-col class="serif"><a href="https://instagram.com/parkmainhartford">@parkmainhartford</a></b-col>
      </b-row>
    </b-container>
    <b-container fluid class="contact p-5">
      <b-container>
        <b-row align-v="center">
          <b-col md="6" class="text-md-left text-center">
            <span class="white">O</span> <a href="tel:2038579904">203.857.9904</a> <br />
            <span class="white">E</span> <a href="mailto:hello@parkmainhartford.com">hello@parkmainhartford.com</a>
          </b-col>
          <b-col xs="12" class="d-block d-md-none"><br /></b-col>
          <b-col class="text-md-right text-center " md="6"
            >97 Main Street<br />
            Hartford, CT 06106</b-col
          >
        </b-row>
      </b-container>
    </b-container>

    <b-container fluid class="footer p-5">
      <b-row align-v="center">
        <b-col class="col-xs-12 text-center" style="padding-bottom:20px;margin-top:50px;line-height:50px;">
          <a href="http://spinrep.com/" target="_blank"
            ><img src="https://sono19day.com/wp-content/themes/twentythirteen/images/Spinnaker-logo.svg" width="200"/></a
          ><br />Building Places of Value
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center copyright">
          &copy; {{ getYear }} Spinnaker Real Estate Partners – ALL RIGHTS RESERVED | <a v-b-modal.modal-1>PRIVACY & TERMS</a>
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="modal-1" title="Privacy &amp; Terms">
      <div>
        <p>
          Park & Main Hartford (the “Company”) is committed to protecting the privacy of certain data that is collected from you and that
          you submit when you access this website or websites and applications under our operation and control (collectively, the “Site”).
          We recognize the sensitive nature of much of the information with which we are entrusted on a daily basis, and we are committed to
          the high standards of privacy and security. The nature of our business requires that at times we gather information that is of a
          personal nature and that you wish to keep protected. This Privacy Policy (“Policy”) is intended to familiarize you with our
          policies for the gathering, use, maintenance and protection of personal information on the Site.
        </p>

        <p>
          More specifically, this Policy summarizes how the Company handles the personal and other information users of the Site provide
          while accessing and using the Site. Please review this Policy carefully. The Company reserves the right to change its Policy at
          any time, in which event the Site will contain a notice of the change. Changes will be effective immediately upon posting to the
          Site. By accessing the Site, you are indicating your agreement to the Company’s Policy. If you have any questions or concerns
          about the Policy, please contact us using the information provided below.
        </p>

        <p>
          <strong>Information Collected. </strong>As you are probably aware, we may collect information from a variety of sources. We
          collect and use this information in order to carry out our business functions and provide a high quality of service. In general,
          the Company may compile statistical information concerning the usage of the Site. This information allows the Company to monitor
          its utilization and continuously improve its quality. Examples of this information would include, but not be limited to, the
          number of visitors to the Site, or to sections or pages within the Site, patterns of traffic flowing through the Site, length of
          time spent on the Site, or in sections or pages of the Site, the other sites that refer visitors to the Site, the pages of the
          Site that visitors frequently use as entry and exit points, utilization of the browser and operating systems and versions used by
          visitors to the Site.
        </p>

        <p>
          In order to compile this information, the Company may collect and store your IP address, your operating system version, your
          browser version, the pages you visit within the Site, the length of time you spend on pages within the Site, the site from which
          you linked to ours, search terms you used in search engines which resulted in you linking to the Site, etc. While all of this
          information can be associated with the IP address your computer had while you visited the Site, it will not be associated with you
          as an individual, or associated with any other information you may submit through the Site, or that Company may store about you
          for any other purposes.
        </p>

        <p>
          Additionally, if you decide to provide your personal information, the types of personal information we may collect about you
          through the Site includes: (i) your contact information, such as name, address, e-mail address, phone numbers; (ii) account set-up
          and, if applicable, payment information when you desire to use and purchase our services, such as credit or debit card account
          numbers; and (iii) employment history information if you seek employment with us which might include your work history and salary
          information.
        </p>

        <p>
          The Company may use web site “cookies” in the process of compiling the statistical information mentioned above and to tailor
          certain pages of the Site for your browsing convenience. A cookie file can contain information such as the URL you came from, your
          computer's IP address and domain type (e.g., .com or .org, etc.), your browser type, the country, state and telephone area code
          where your server is located, the pages of our site that were viewed during a visit, and any search terms that you entered on our
          site. This information generally is used for internal purposes, and we do not link your URL or IP address to any personal
          information unless you have logged into our Site with an established account login and password. You have the option of setting
          your browser to reject cookies, but our Site may not function correctly if you do. Refer to the Help feature of your browser for
          information on disabling cookies.
        </p>

        <p>
          The Site is not directed to children. The Company does not knowingly collect any personal information from children. If you are
          concerned about your child’s use of the Site, you may use web-filtering technology to supervise or limit access to the Site.
        </p>

        <p>
          <strong>Use of Information. </strong>In general, we will use the information we collect about you only for the purpose it was
          collected, as otherwise provided in this Policy, and as permitted or required by law. For example, we might use your information
          to enhance our Site, or to communicate with you about the Site or our products and services.
        </p>

        <p>
          <strong>Disclosure of Information. </strong>In general, we will not disclose your information for any purposes other than those in
          conjunction with the business activities requested of us unless otherwise permitted or required by law or by this Policy. In the
          course of our normal business activities, we may disclose collected information to both affiliates and non-affiliated entities, as
          we deem necessary. For example, and without limitation, the Company may need to use or disclose this information with its
          third-party vendors to manage its website, respond to subpoenas, defend litigation, enhance its services, and protect the
          Company’s legal rights. We endeavor to choose affiliates and non-affiliates with similar standards to ours regarding the
          protection of personal information.
        </p>

        <p>
          In no case will the Company sell or license personal information to third parties, except as required or permitted by law. For
          example, we may share personal information in connection with certain business transactions, such as the acquisition of all or
          substantially all of the Company’s assets. In such cases, we will take appropriate steps under the circumstances and to the extent
          possible to ensure that the recipient agrees to provide privacy protections substantially similar to those established by this
          Policy.
        </p>

        <p>
          <strong>Do Not Track. </strong>“Do Not Track” is a privacy preference that you can set in your Internet search browser that sends
          a signal to a website that you do not want the website operator to track certain browsing information about you. However, because
          our Site is not configured to detect Do Not Track signals from a user’s computer, we are unable to respond to Do Not Track
          requests.
        </p>

        <p>
          <strong>Modifying Your Personal Information</strong>. If you would like to update your personal information on the Site, you may
          provide address. Also, if you wish to unsubscribe from any of our newsletters or other communications for which you have
          registered, you may e-mail us at <a href="mailto:hello@parkmainhartford.com">hello@parkmainhartford.com</a>.
        </p>

        <p>
          <strong>Safeguarding of Information. </strong>No system for safeguarding personal or other information is 100% secure. However, we
          take a number of steps to reasonably safeguard the security of personal information obtained through the Site. Our data centers
          are maintained in a secure environment with appropriate security measures. Our employees are trained to understand the importance
          of confidentiality and are required to adhere to our privacy policies and procedures.
        </p>

        <p>
          <strong>Applicable law. </strong>This Policy is governed by the internal substantive laws of the State of Delaware, without regard
          to its conflict of laws principles. Jurisdiction for any claims arising under or out of this Privacy Policy shall lie exclusively
          with the state and federal courts within Delaware. If any provision of this Policy is found to be invalid by a court having
          competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Policy,
          which shall remain in full force and effect.
        </p>

        <p>
          <strong>Contact Us</strong>. If you have any questions regarding this Policy or our policies in the event of a compromise of your
          information, you may contact us at:
          <strong><a href="mailto:hello@parkmainhartford.com">hello@parkmainhartford.com</a></strong>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//import { loopForm } from '@/components/stay-in-loop-form';
//import { PrivacyModal } from '@/components/privacy-modal.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'Home',
  components: { FontAwesomeIcon, VueSlickCarousel },
  data: function() {
    return {
      showForm: false,
      highlightParagraph: `Whether you are looking to upgrade your current digs, improve your
            WFH locale, or realize a brick and mortar dream, Park & Main is your
            timely crossroads.`,
      settings: {
        centerMode: true,
        centerPadding: '120px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        dots: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '50px',
            },
          },
        ],
      },
    };
  },
  computed: {
    getYear: function() {
      var d = new Date();
      var n = d.getFullYear();
      return n;
    },
  },
};
</script>
